<template>
  <div class="speech-recognition">
    <h1>Real-Time Speech-to-Text</h1>
    <button @click="startRecording" :disabled="isRecording">Start Recording</button>
    <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    <ul>
      <li v-for="(text, index) in transcriptions" :key="index">{{ text }}</li>
    </ul>
    <p><strong>Full Transcript:</strong> {{ fullTranscript }}</p>
  </div>
</template>

<script setup>
import { ref } from "vue";

const wsUrl = "wss://ai-stage.ensofia.app:9443/fast/stream_ws";
let websocket;
// eslint-disable-next-line no-unused-vars
let mediaRecorder;
let audioContext;
let audioInput;
let processor;

const isRecording = ref(false);
const transcriptions = ref([]);
const errorMessage = ref("");
const fullTranscript = ref("");
const lastSegment = ref("");

const startRecording = async () => {
  if (!navigator.mediaDevices?.getUserMedia) {
    errorMessage.value = "Audio recording is not supported on this browser.";
    return;
  }

  try {
    isRecording.value = true;

    // Start WebSocket connection
    websocket = new WebSocket(wsUrl);

    
    websocket.onmessage = (event) => {
  const newSegment = event.data.trim(); // Get the new transcript segment
console.log(event)
  // Only append truly new parts
  if (!lastSegment.value || !newSegment.startsWith(lastSegment.value)) {
    const uniquePart = newSegment.replace(lastSegment.value, "").trim();

    if (uniquePart) {
      // Append to cumulative transcript
      fullTranscript.value = `${fullTranscript.value} ${uniquePart}`.trim();
      transcriptions.value.push(uniquePart); // Add to visible segments
    }
  }

  // Update the last segment
  lastSegment.value = newSegment;
};

// Handle WebSocket errors and closure
websocket.onerror = () => {
  console.error("WebSocket encountered an error.");
};


    websocket.onerror = (event) => {
      errorMessage.value = "WebSocket error occurred.";
      console.error(event);
    };

    websocket.onclose = () => {
      isRecording.value = false;
    };

    // Request microphone access
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // Initialize audio processing
    audioContext = new AudioContext();
    audioInput = audioContext.createMediaStreamSource(stream);
    processor = audioContext.createScriptProcessor(4096, 1, 1);

    processor.onaudioprocess = (event) => {
      if (websocket.readyState === WebSocket.OPEN) {
        const audioData = event.inputBuffer.getChannelData(0);
        const int16Audio = Float32ArrayToInt16(audioData);
        websocket.send(int16Audio);
      }
    };

    audioInput.connect(processor);
    processor.connect(audioContext.destination);
  } catch (err) {
    errorMessage.value = "Error accessing microphone: " + err.message;
    console.error(err);
    stopRecording();
  }
};

const stopRecording = () => {
  if (audioInput) audioInput.disconnect();
  if (processor) processor.disconnect();
  if (audioContext) audioContext.close();
  if (websocket) websocket.close();

  isRecording.value = false;
};

// Helper: Convert Float32Array to Int16Array
const Float32ArrayToInt16 = (float32Array) => {
  const int16Array = new Int16Array(float32Array.length);
  float32Array.forEach((sample, index) => {
    int16Array[index] = Math.max(-1, Math.min(1, sample)) * 0x7fff;
  });
  return int16Array;
};
</script>

<style>
.speech-recognition {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
}
button {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
}
.error {
  color: red;
}
</style>
