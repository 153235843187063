<template>
  <div class="d-flex flex-column align-items-center my-4">
    <router-link :to="`/addEnconter`" class="text-decoration-none" >
      <button  class="add-topic-btn my-3">+ Add New Encounter</button>
    </router-link>
   <input 
      v-model="searchQuery" 
      type="text" 
      class="form-control mb-3" 
      placeholder="Search Topics" 
      @input="search"
    /> 
    <ul v-if="(isSearching ? searchTopics.length > 0 : topics.length > 0)" class="list-group" style="width:100%">
      <li v-for="topic in  (isSearching ? searchTopics : topics)" :key="topic.id" class="list-group-item d-flex justify-content-between align-items-center">
        
        <a  class="" @click="() => {storePatientName(topic.name);topic.is_draft ? goToDraft(topic.id,topic.name) : goToDetails(topic.id,topic.name);}">
          <i v-if="topic.is_draft" class="fa fa-pen-to-square text-info" aria-hidden="true"></i>
          <i v-else class="fa-solid fa-file text-success" aria-hidden="true"></i>
          <span class="ms-2" >{{ topic.name }}</span>
        </a>
        <div class="dropdown">
          <button class="btn btn-white " @click="toggleDropdown(topic.id)">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <ul v-if="topic.showDropdown" class="dropdown-menu">
            <li><a class="dropdown-item"  @click="handleSend(topic, 'pdf')">Send as PDF</a></li>
            <li><a class="dropdown-item"  @click="handleSend(topic, 'docx')">Send as DOCX</a></li>
          </ul>
        </div>
      </li>
    </ul>
    <p v-else-if="isSearching && searchTopics.length === 0">No results found.</p>
    <p v-else>No topics available.</p>
    <paginate class="my-3"
      v-if="topics.length"
      :page-count="totalPages"
      :click-handler="handlePageClick"
      :prev-text="'-'"
      :next-text="'+'"
      :container-class="'pagination'"
      :page-range="1"
      :page-class="'page-item'"
      :active-class="'active'"
      :page-link-class="'page-link'"
    />
  </div>
</template>

<script setup>

import { ref, onMounted ,watch,computed } from 'vue';
import { useStore } from 'vuex';
import {   useRouter} from 'vue-router';
import Paginate from 'vuejs-paginate-next'; 
import { useToast } from 'vue-toastification';
import {setupJWTInterceptor} from '@/services/axios';

const JWTinterceptor = setupJWTInterceptor();
const store = useStore();
const currentView = computed(() => store.state.navigationData?.view || '');
const router = useRouter();
const topics = ref([]);
const searchTopics = ref([]);
const error = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const toast = useToast();
const token = localStorage.getItem('token');
const searchQuery = ref(''); // Search query entered by the user
const isSearching = ref(false);


const storePatientName = (name) => {
  store.commit('setPatientName', name);
};


const getTopics = async (page, dialogId) => {
  try {
    const response = await JWTinterceptor.get(`${process.env.VUE_APP_SOFA_API}chat/${dialogId}`, {
      params: { page },
    });
    topics.value = response.data.responseData.chats.map(chat => ({
      ...chat,
      selectedOption: '', 
      showDropdown: false 
    }));
    currentPage.value = response.data.responseData.page;
    totalPages.value = response.data.responseData.total_pages;
  } catch (err) {
    if (err.response?.status === 401) {
      await getTopics(token, page, dialogId); 
    } else {
      error.value = err.message;
      // toast.error(err.message)
    }
  }
};

const search = async () => {
  // let searchQuery =searchQuery.value;
  if (!searchQuery.value) {
    // Reset to paginated mode if search query is empty
    isSearching.value = false;
    await getTopics(currentPage.value, 2);
    return;
  }
  try {
    const response = await JWTinterceptor.get(`${process.env.VUE_APP_SOFA_API}search_chat/${searchQuery.value}`
    
    );
    console.log(response.data.responseData); 
    const chats = response.data?.responseData?.chats;
    if (!chats || chats ===  "No Chats") {
          searchTopics.value = [];
          isSearching.value = true;
    } else {
      searchTopics.value = chats.map(chat => ({
        ...chat,
        selectedOption: '',
        showDropdown: false,
      }));
      isSearching.value = true;
      currentPage.value = response.data.responseData.page;
    totalPages.value = response.data.responseData.total_pages;
    }
  } catch (err) {
    
      error.value = err.message;
      console.error(err.message)
    
  }
};
const handlePageClick = (pageNum) => {
  if (pageNum !== currentPage.value) {
    getTopics(pageNum, 2); 
  }
};
watch(searchQuery, (newQuery) => {
  if (newQuery.length === 0) {
    isSearching.value = false;
    getTopics(currentPage.value,2);
  }
});
// watch(
//   () => navigationData.value?.view  || '',
//   async(oldView , newView) => {
//     if (newView === oldView) return;
//     console.log(`View changed from ${oldView} to ${newView}`);
//     await getTopics(currentPage.value, 2); // Refresh topics when returning
//   }
  
// );
watch(
  currentView,
      async (newView, oldView) => {
        if (newView === oldView) return; // Avoid unnecessary refresh if the view is the same
        console.log(`View changed from ${oldView} to ${newView}`);
        await getTopics(currentPage.value, 2); // Refresh topics when returning
      }
    );

onMounted(() => { 
  getTopics(currentPage.value, 2);  
});
const goToDetails = (topicId) => {
  // alert(topicId)
  const data = {  view: 'encounter' };
  // console.log(data.patientNameV)
  store.commit('setTopicId', topicId);
  // console.log(store.state.topicId)
  store.dispatch('navigation/saveNavigationData', data);
  router.push({ name: 'encounter' }); 
};
const goToDraft = (topicId,patientNameV) => {
  const data = { topicId, patientNameV ,view: 'record' } ;
  store.dispatch('navigation/saveNavigationData', data);
  router.push({ name: 'encounter'});
};
const getSendPdf = async (chatId, type) => {
  try {
    const result = await JWTinterceptor.post(`${process.env.VUE_APP_SOFA_API}send_mail/${chatId}/`, {
      file_type: type,
    });
    toast.success("Mail Sent successfully");
    return result;
  } catch (e) {
    if (e.response) {
      toast.error(`Status code: ${e.response.status}`);
      if (e.response.status === 401) {
        // eslint-disable-next-line no-undef
        return response;
      }
    } else {
      toast.error("Something went wrong");
    }
    return e.response;
  }
};

const handleSend = async (topic, type) => {
  await getSendPdf(topic.id, type);
  topic.showDropdown = false; 
};

const toggleDropdown = (topicId) => {
  topics.value.forEach(topic => {
    if (topic.id === topicId) {
      topic.showDropdown = !topic.showDropdown;
    } else {
      topic.showDropdown = false;
    }
  });
};
</script>

<style scoped>
.topics-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.topic-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.topic-item:hover {
  background-color: #f0f0f0;
}

.topic-link {
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-decoration: none;
  color: #333;
}

.draft-icon,
.encounter-icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #007bff;
}

.topic-name {
  flex-grow: 1;
  font-weight: 500;
}

.share-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
}

.share-btn:focus {
  outline: none;
}
.add-topic-btn {
  background-color: #6dc7f0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-topic-btn:hover {
  background-color: #1f86ae;
}

.dots-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
}

.dots-btn:focus {
  outline: none;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  right:0;
}

.dropdown-menu a {
  padding: 10px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
a{
  text-decoration: none;
  cursor: pointer;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

@media (max-width: 480px) {
  .topic-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .topic-name {
    margin-bottom: 10px;
  }

  .share-btn {
    align-self: flex-end;
  }
}
</style>