
export default {
    namespaced: true,
    state: {
      navigationData: null,
    },
    mutations: {
      setNavigationData(state, data) {
        state.navigationData = data;
      },
      clearNavigationData(state) {
        state.navigationData = null;
      },
    },
    actions: {
      saveNavigationData({ commit }, data) {
        commit('setNavigationData', data);
      },
      resetNavigationData({ commit }) {
        commit('clearNavigationData');
      },
    },
    getters: {
      getNavigationData: (state) => state.navigationData,
    },
  };
  