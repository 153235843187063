import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import store from './store';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const app = createApp(App);

app.use(router); 
app.use(store);
app.use(Toast);
app.use(VueTelInput);

app.mount('#app');

import 'bootstrap/dist/js/bootstrap.js'