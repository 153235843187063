import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

const toast = useToast();

export function useRecorder(language) {
  const isRecording = ref(false);
  const transcription = ref('');
  const error = ref(null);
  let recognition = null;
  let transcriptionChunks = [];
  let lastTranscript = '';
  let autoRestartInterval = null;
  let isRestarting = false;

  const initializeRecognition = () => {
    try {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        throw new Error('Browser does not support SpeechRecognition.');
      }

      recognition = new SpeechRecognition();
      recognition.lang = language.value === 'en' ? 'en-US' : 'es-ES';
      recognition.interimResults = true;
      recognition.continuous = true;

      recognition.onstart = () => {
        isRecording.value = true;
        error.value = null;
        console.log('SpeechRecognition started.');
      };

      recognition.onresult = (event) => {
        const results = Array.from(event.results);
        const finalResults = results.filter((result) => result.isFinal);
        const currentTranscript = finalResults.map((result) => result[0].transcript).join(' ');

        if (currentTranscript && currentTranscript !== lastTranscript) {
          transcriptionChunks.push(currentTranscript.replace(lastTranscript, '').trim());
          lastTranscript = currentTranscript;
        }

        transcription.value = transcriptionChunks.join(' ');
        console.log('Current Transcript:', transcription.value);
      };

      recognition.onerror = (event) => {
        error.value = event.error;
        console.error('SpeechRecognition error:', event.error);
        toast.error(`SpeechRecognition error: ${event.error}`);
        if (event.error === 'network' || event.error === 'aborted') {
          handleRestart();
        }
      };

      recognition.onend = () => {
        if (!isRestarting && isRecording.value) {
          handleRestart(); 
        }
      };
    } catch (err) {
      error.value = err.message;
      toast.error(err.message);
    }
  };

  const startRecording = () => {
    if (!recognition) {
      initializeRecognition();
    }
    if (recognition && !isRestarting) {
      recognition.start();
      setupAutoRestart();
    } else {
      error.value = 'SpeechRecognition not initialized or already restarting.';
      toast.error(error.value);
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      isRecording.value = false;
      clearInterval(autoRestartInterval); 
      autoRestartInterval = null; 
      isRestarting = false; 
    }
  };

  const handleRestart = () => {
    if (!isRecording.value || isRestarting) {
      return; 
    }

    isRestarting = true;
    console.log('Restarting SpeechRecognition...');
    setTimeout(() => {
      try {
        if (recognition) {
          recognition.abort(); 
          recognition.start(); 
          console.log('SpeechRecognition restarted.');
        }
      } catch (err) {
        console.log('Error restarting SpeechRecognition:', err);
        // toast.error('Failed to restart SpeechRecognition.');
      } finally {
        isRestarting = false;
      }
    }, 1000); 
  };

  const setupAutoRestart = () => {
    clearInterval(autoRestartInterval); 
    autoRestartInterval = setInterval(() => {
      if (isRecording.value) {
        console.log('Automatically restarting SpeechRecognition session...');
        handleRestart();
      }
    }, 5 * 60 * 1000); 
  };

  watch(language, (newLang) => {
    if (recognition) {
      recognition.lang = newLang === 'en' ? 'en-US' : 'es-ES';
    }
  });

  return {
    isRecording,
    transcription,
    error,
    startRecording,
    stopRecording,
  };
}
