// src/store/modules/websocket.js
const state = {
    socket: null,
    isConnected: false,
};

const mutations = {
    SET_SOCKET(state, socket) {
        state.socket = socket;
    },
    SET_CONNECTED(state, status) {
        state.isConnected = status;
    },
};

const actions = {
    connect({ commit, state }, { wsUrl, token, handleMessage }) {
        // if (state.isConnected) {
        //     console.log('connected')
            
        //     return; // Prevent reconnecting if already connected
        // }
        if(state.socket && state.socket.readyState === WebSocket.OPEN){
            console.log("open")
        }else{
            console.log("closed")
        }
        const socket = new WebSocket(wsUrl);

        socket.addEventListener('open', () => {
            console.log('WebSocket connection established.');
            commit('SET_SOCKET', socket);
            commit('SET_CONNECTED', true);

            if (token) {
                socket.send(JSON.stringify({ token }));
            } else {
                console.error('Token is missing!');
            }
        });

        socket.addEventListener('message', (event) => {
            try {
                const receivedData = JSON.parse(event.data);
                console.log('Received message:', receivedData);
                if (handleMessage) {
                    handleMessage(receivedData);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        });

        socket.addEventListener('close', (event) => {
            console.log(`WebSocket closed: Code ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
            commit('SET_CONNECTED', false);
        });

        socket.addEventListener('error', (error) => {
            console.error('WebSocket error:', error);
        });
    },

    sendMessage({ state }, message) {
        if (state.isConnected && state.socket.readyState === WebSocket.OPEN) {
            state.socket.send(JSON.stringify(message));
            console.log('Message sent to WebSocket:', message);
        } else {
            console.warn('WebSocket is not open. Unable to send message.');
        }
    },

    // closeConnection({ state, commit }) {
    //   if (state.socket && state.socket.readyState !== WebSocket.CLOSED) {
    //     state.socket.close();
    //     commit('SET_CONNECTED', false);
    //     console.log("WebSocket connection closed");
    //   }
    // },
    closeConnection({ state, commit }) {
        if (state.socket instanceof WebSocket && state.socket.readyState !== WebSocket.CLOSED) {
            state.socket.close();
            // console.log('CONNECTED', state.isConnected);
            commit('SET_CONNECTED', false);
            console.log("WebSocket connection closed");
        } else {
            console.warn("WebSocket is not open or already closed.");
            // console.log('CONNECTED', state.isConnected);
            commit('SET_CONNECTED', false);


        }
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
