<template>
  <div class="audio-recorder">
     <button @click="toggleRecording">
      <i :class="isRecording ? 'fas fa-pause' : 'fas fa-microphone'"></i>
    </button>
    <button v-if="translation" @click="playTranslation">
      <i class="fa-solid fa-volume-up"></i> Speak Translation
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRecorder } from '../utils/useRecord2'; 
import { translateText } from '../utils/useTranslate'; 
import { useSpeak } from '../utils/useSpeech';
import { defineProps } from 'vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['send-message']); 

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  language: {
    type: String,
    required: true
  }
});
const language = ref(props.language);
const { isRecording, transcription, startRecording, stopRecording } = useRecorder(language);
const { speakText,  } = useSpeak(language.value); 
const counter = ref(0);
const micIcon = ref('fa-solid fa-microphone');
const isSending = ref(false);
const translation = ref(''); 
let interval = null;

const toggleRecording = () => {
  if (isRecording.value) {
    stopRecording();
    isSending.value = true;
    clearInterval(interval);
    interval = null;
    micIcon.value = 'fa-solid fa-microphone';
  } else {
    startRecording();
    console.log(language.value,"sd")
    interval = setInterval(() => {
      counter.value += 1;
    }, 1000);
    micIcon.value = 'fa-solid fa-pause';
  }
};

watch(transcription, async (newValue) => {
  if (newValue) {
    isSending.value = false; 
    try {
      translation.value = await translateText(newValue, language.value); // Translate the transcription
      console.log('Translation:', translation.value);
      console.log(language)
      emit('send-message', { transcription: newValue, translation: translation.value });
      await speakText(translation.value);
    } catch (error) {
      console.error('Error translating transcription:', error);
    }
  }
});
const playTranslation = () => {
  if (translation.value) {
    speakText(translation.value);
  }
};
</script>

<style scoped>
.audio-recorder {
  margin: 20px 0;
}
button {
  padding: 10px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button i {
  color: blue;
}

.error {
  color: red;
}
</style>
